@keyframes blink {
    0% { box-shadow: inset 0 0 250px 0 red; }
    50% { box-shadow: none; }
    100% { box-shadow: inset 0 0 250px 0 red; }
}

@-webkit-keyframes blink {
    0% { box-shadow:  0 0 250px 0 red; }
    50% { box-shadow: 0 0 0; }
    100% { box-shadow:  0 0 250px 0 red; }
}

.blink {
    -webkit-animation: blink 1.0s linear ;
    -moz-animation: blink 1.0s linear ;
    -o-animation: blink 1.0s linear ;
    animation: blink 1.0s linear ;
    animation-iteration-count: infinite;
    position: absolute;
    height: 100vh;
    width: 100%;
    z-index: 10;
    background: none !important;
    display: flex;
    align-items: center;
    justify-content: center;
}
.blink-wrap {
    height: 100vh;
    width: 100%;
    z-index: 10;
    background: none !important;
    position: absolute;
}

.blink span {
    background-color: red;
    font-size: -webkit-xxx-large;
    border-radius: 20px;
    padding: 20px 40px;
    box-shadow: 20px 20px 20px black;
}

/* .cards-wrapper {
    position:fixed;
    top: 150px;
    left: 200px;
} */

.cards-wrapper {
    position:relative;
    top: 4em;
    left: 0;
    /* width: 400px !important; */
} 

.cards-menu-wrapper {
    position:fixed;
    top: 105px;
    right: 60px;
    background-color: rgba(255, 255, 2555, 0.8);
}

.card-close-icon {
    font-size: 1.5em;
    color: tomato !important;
}

.card-menu-icon {
    font-size: 2.5em;
    margin-left: 5px;
    margin-right: 5px;
    /* color: tomato !important; */
}

google-chart {
    height: 200px;
    width: 300px;
    
}

.actions-menu-wrapper {
    display: flex;
    position:fixed;
    top: 102px;
    left: 50%;
    background-color: rgba(255, 255, 2555, 0.8);
    width: auto
}

.btnAction {
    /* background: none padding-box rgb(255, 255, 255); */
    display: table-cell;
    border: 0px;
    margin: 0px;
    padding: 0px 17px;
    text-transform: none;
    appearance: none;
    position: relative;
    cursor: pointer;
    user-select: none;
    direction: ltr;
    overflow: hidden;
    text-align: center;
    height: 40px;
    vertical-align: middle;
    /* color: rgb(0, 0, 0); */
    font-family: Roboto, Arial, sans-serif;
    font-size: 18px;
    border-bottom-left-radius: 2px;
    border-top-left-radius: 2px;
    box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
    min-width: 45px;
    font-weight: 500;
}

.btActionCloseAlert {
    color: rgb(255, 255, 255);
    background: none padding-box hsl(143, 90%, 43%);
    margin-right: 4px;
} 
.btActionClosePage {
    color: rgb(255, 255, 255);
    background: none padding-box rgb(255, 0, 0);
} 

.ant-modal-close {
    display: none !important;
}